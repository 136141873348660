import React, { useEffect, lazy, Suspense } from 'react'
import { callGetRealtimeUserDetailsApi } from '@api'
import { onAuthStateChanged } from '@api/auth'
import {
  userSetUserDetailsSuccessAction,
  clearCurrentUserDataAction
} from '@actions'
import { useStore } from '@store'
import Loader from '@shared/Loader'
import PrivateRoute from '@shared/PrivateRoute'
import { Router, Switch, Route } from 'react-router-dom'
import {
  HOME_ROUTE,
  SEARCH_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_ROUTE,
  RESET_PASSWORD_ROUTE,
  MY_PROFILE_ROUTE,
  BID_DETAILS_ROUTE,
  DASHBOARD_ROUTE,
  PROFILE_VIEW_ROUTE,
  SCHEDULE_CALL,
  SCHEDULED_CALL,
  SCHEDULE_GROUP,
  VIDEO_CALL_ROUTE,
  ZEGO_VIDEO_CALL_ROUTE,
  WISHLIST_ROUTE,
  JOBS_ROUTE,
  HOW_IT_WORKS_ROUTE,
  COMPANY_INVITE_LINK,
  TERMS_AND_CONDITIONS_ROUTE,
  PRIVACY_POLICY_ROUTE,
  AFFILIATE_ROUTE,
  AFFILIATE_LINK,
  ADD_GROUP_USER_ROUTE,
  COMPANY_INFO_ROUTE
} from '@constants/routes'
import history from '@history/'
import { ToastContainer } from 'react-toastify'
const Home = lazy(() => import('@views/Home'))
const SearchResults = lazy(() => import('@views/SearchResults'))
const Wishlist = lazy(() => import('@views/Wishlist'))
const BidDetails = lazy(() => import('@views/BidDetails'))
const Auth = lazy(() => import('@views/Auth'))
const Signup = lazy(() => import('@views/Auth/Signup'))
const MyProfile = lazy(() => import('@views/MyProfile'))
const Dashboard = lazy(() => import('@views/Dashboard'))
const ProfileView = lazy(() => import('@views/ProfileView'))
const ResetPassword = lazy(() => import('@views/Auth/ResetPassword'))
const ScheduleCall = lazy(() => import('@views/ScheduleCall'))
const ScheduleGroupCall = lazy(() =>
  import('@views/ScheduleCall/ScheduleGroupCall')
)
const ScheduledCall = lazy(() => import('@views/ScheduledCall'))
const AgoraVideoCall = lazy(() => import('@views/AgoraVideoCall'))
const ZegoVideoCall = lazy(() => import('@views/ZegoVideoCall'))
const JobPostings = lazy(() => import('@views/JobPostings'))
const NotFound = lazy(() => import('@views/NotFound'))
import 'react-toastify/dist/ReactToastify.css'
import './style.scss'
import Affiliate from '@views/Affiliate'
// import AffiliateInviteLink from '@views/Affiliate/InviteLink'
const AffiliateInviteLink = lazy(() => import('@views/Affiliate/InviteLink'))
const HowItWorks = lazy(() => import('@views/HowItWorks'))
const TermsAndConditions = lazy(() => import('@views/TermsAndConditions'))
const PrivacyPolicy = lazy(() => import('@views/PrivacyPolicy'))
const InviteLink = lazy(() => import('@views/Dashboard/MyCompany/InviteLink'))
const AddGroupUser = lazy(() => import('@views/Dashboard/AddGroupUser'))
const CompanyInfo = lazy(() => import('@views/CompanyView'))

const App = () => {
  const { useSelector, dispatch } = useStore()
  const query = new URLSearchParams(location.search);
  const redirectRoutes = query.get('redirect') ? `?redirect=${query.get('redirect')}` : '';
  const { loggedInUserData: { userId } = {}, isAppLoading } = useSelector(
    state => {
      return {
        loggedInUserData: state.loggedInUserData,
        isAppLoading: state.isAppLoading
      }
    }
  )

  const resData = res => {
    const { status, data } = res
    if (status === 200) dispatch(userSetUserDetailsSuccessAction(data))
    else dispatch(clearCurrentUserDataAction())
  }

  useEffect(() => {
    callGetRealtimeUserDetailsApi(userId, resData)
  }, [userId])

  useEffect(() => {
    onAuthStateChanged()
  }, [userId])

  return (
    <div>
      {isAppLoading && <Loader />}
      <Suspense fallback={<Loader />}>
        <Router history={history}>
          <Switch>
            <PrivateRoute exact path={HOME_ROUTE} component={Home} />
            <Route exact path={SEARCH_ROUTE} component={SearchResults} />
            <Route exact path={JOBS_ROUTE} component={JobPostings} />
            <Route exact path={WISHLIST_ROUTE} component={Wishlist} />
            <Route exact path={`${ADD_GROUP_USER_ROUTE}/:id`} component={AddGroupUser} />
            <PrivateRoute
              exact
              path={`${BID_DETAILS_ROUTE}/:reqId/:id`}
              component={BidDetails}
            />
            <PrivateRoute path={DASHBOARD_ROUTE} component={Dashboard} />
            <PrivateRoute
              exact
              path={`${SCHEDULE_CALL}/:id`}
              component={ScheduleCall}
            />
            <PrivateRoute
              exact
              path={`${SCHEDULE_GROUP}/:id`}
              component={ScheduleGroupCall}
            />
            <PrivateRoute
              exact
              path={`${SCHEDULED_CALL}/:reqId/:id`}
              component={ScheduledCall}
            />

            <Route
              exact
              path={`${PROFILE_VIEW_ROUTE}/:id`}
              component={ProfileView}
            />
            <Route exact path={`${SIGNIN_ROUTE}?redirect=${query.get('redirect')}`} component={Auth} />
            <Route path={`${SIGNIN_ROUTE}`} component={Auth} />
            <Route exact path={SIGNUP_ROUTE} component={Signup} />
            <Route
              exact
              path={RESET_PASSWORD_ROUTE}
              component={ResetPassword}
            />

            <PrivateRoute
              path={`${MY_PROFILE_ROUTE}/:id`}
              component={MyProfile}
            />
            <PrivateRoute path={MY_PROFILE_ROUTE} component={MyProfile} />
            <Route
              path={`${VIDEO_CALL_ROUTE}/:reqId/:id`}
              component={AgoraVideoCall}
            />
            <Route
              path={`${ZEGO_VIDEO_CALL_ROUTE}/:roomId`}
              component={ZegoVideoCall}
            />
            <Route path={HOW_IT_WORKS_ROUTE} component={HowItWorks} />
            <Route
              path={TERMS_AND_CONDITIONS_ROUTE}
              component={TermsAndConditions}
            />
            <Route path={PRIVACY_POLICY_ROUTE} component={PrivacyPolicy} />
            <Route path={AFFILIATE_ROUTE} component={Affiliate} />
            <Route path={`${AFFILIATE_LINK}/:inviteId`} component={AffiliateInviteLink} />
            <Route path={`${COMPANY_INFO_ROUTE}/:orgId`} component={CompanyInfo} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </Suspense>
      <ToastContainer autoClose={2000} />
    </div>
  )
}

export default App
