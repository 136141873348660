import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useStore } from '@store'
import { userSetNotificationSuccessAction } from '@actions'
import {
  MESSENGER_ROUTE,
  SCHEDULED_CALL,
  BID_DETAILS_ROUTE,
  VIDEO_CALL_ROUTE,
  SCHEDULE_GROUP,
  POSTS_ROUTE,
  DASHBOARD_ROUTE
} from '@constants/routes'
import { WavSoundEffect, VttSoundEffect, DATE_STYLE } from '@constants/'
import { callGetRealtimeNotificationsApi } from '@api/notification'
import DefaultPic from '@assets/images/DefaultPic.jpg'
import { getDateTimestamp } from '@utils/'
import Bell from '@assets/images/bell.svg'

const Notifications = () => {
  const { dispatch, useSelector } = useStore()

  const { loggedInUserData: { userId = '' } = {} } = useSelector(state => {
    return {
      loggedInUserData: state.loggedInUserData
    }
  })

  const [notifications, setNotifications] = useState([])
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const notifyRef = useRef()
  const soundRef = useRef()

  const notify = notifications.filter(item => !item.isRead).length

  const resData = res => {
    setNotifications(res)
    dispatch(userSetNotificationSuccessAction(res))
  }

  useEffect(() => {
    if (userId) {
      const subscribe = callGetRealtimeNotificationsApi(userId, resData)
      return () => {
        subscribe
      }
    }
  }, [userId])

  // type is notification type
  // senderId is which user has sent
  // id is document id
  const getLink = (type, senderId, id) => {
    if (type === 'chat')
      return `${DASHBOARD_ROUTE}${MESSENGER_ROUTE}/${senderId}`
    else if (type === 'schedule') return `${SCHEDULED_CALL}/${senderId}/${id}`
    else if (type === 'bid') return `${BID_DETAILS_ROUTE}/${senderId}/${id}`
    else if (type === 'call') return `${VIDEO_CALL_ROUTE}/${senderId}/${id}`
    else if (type === 'group') return `${SCHEDULE_GROUP}/${id}`
    else if (type === 'groupcall') return `${SCHEDULE_GROUP}/${id}`
    else if (type === 'post') return `${DASHBOARD_ROUTE}${POSTS_ROUTE}/${id}`
  }

  useEffect(() => {
    if (isMenuOpen) {
      const checkIfClickedOutside = e => {
        if (
          isMenuOpen &&
          notifyRef.current &&
          !notifyRef.current.contains(e.target)
        ) {
          setIsMenuOpen(false)
        }
      }
      document.addEventListener('mousedown', checkIfClickedOutside)
      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside)
      }
    }
  }, [isMenuOpen])

  useEffect(() => {
    if (notify > 0) {
      soundRef && soundRef.current ? soundRef.current.play() : ''
      // if (typeof window !== 'undefined') {
      //   const audio = document.getElementById("notificatio_id")
      //   audio.muted = false;
      //   audio.play();
      // }
    }
  }, [notify])

  return (
    <div>
      <div className='Notify'>
        <div onClick={() => setIsMenuOpen(true)}>
          <img src={Bell} />
          <div className='Count'>{notify}</div>
        </div>
        {isMenuOpen && (
          <div className='notificationDropdown' ref={notifyRef}>
            {notifications.map(item => {
              return (
                <a
                  className='notificationsItem'
                  key={item._id}
                  href={getLink(
                    item.type,
                    item.senderId,
                    item.refId ? item.refId : item._id
                  )}
                >
                  <img src={item.senderPic || DefaultPic} alt='img' />
                  <div className='text'>
                    <div className='header'>
                      <h4>{(item.senderName).slice(0, 16)}</h4>
                      <span className='badge badge-dark'>{item.type}</span>
                    </div>
                    <p>{item.message}</p>
                    <span className='time'>
                      {getDateTimestamp(item.createdAt, DATE_STYLE.LONG).dateTime}
                    </span>
                  </div>
                </a>
              )
            })}
          </div>
        )}
      </div>
      <audio
        id={'notificatio_id'}
        data-lp-point='audio_tag'
        controls
        hidden
        preload='auto'
        aria-live='polite'
        src={WavSoundEffect}
        aria-label='Conversation Notification'
        ref={soundRef}
      >
        <track
          kind='captions'
          data-lp-point='track_tag'
          src={VttSoundEffect}
          label='pop'
        />
      </audio>
    </div>
  )
}

export default Notifications
