import { db, serverTimestamp } from '@src/firebase'
import addToaster from '@shared/Notification'

/** Set wishlist
 * @param {Object} payload
 * @param {Object} payload.userId
 * @param {Object} payload.freelancerId
 **/

export const callAddCardWishlistApi = async (userId, freelancerId) => {
  try {
    db.collection(`users/${userId}/wishlist`).doc(freelancerId).set({
      createdAt: serverTimestamp,
      freelancerId: freelancerId
    })
    return { status: 200 }
  } catch (error) {
    addToaster('error', error.message)
  }
}

export const callRemoveCardWishlistApi = async (userId, docId) => {
  try {
    db.collection(`users/${userId}/wishlist`).doc(docId).delete()
    return { status: 200 }
  } catch (error) {
    addToaster('error', error.message)
  }
}

/** Get wishlist freelancer
 * @param {Object} payload.userId
 */
export const callGetRealtimeWishlistApi = async (userId, resData) => {
  try {
    db.collection(`users/${userId}/wishlist`)
      .orderBy('createdAt', 'asc')
      .onSnapshot(snapshot => {
        const wishlist = snapshot.docs.map(item => {
          const _id = item.id
          return { ...item.data(), _id }
        })
        resData(wishlist)
      })
  } catch (error) {
    addToaster('error', error.message)
  }
}

/** Get wishlist freelancer details
 * @param {Object} payload.userId
 */
export const callGetWishlistDetailsApi = async (userId, freelancerId) => {
  try {
    const res = await db
      .collection(`users/${userId}/wishlist`)
      .doc(freelancerId)
      .get()
    return { status: 200, data: res.data() }
  } catch (error) {
    addToaster('error', error.message)
  }
}
