export const MY_PROFILE_LEFT_NAVE = [
  'Personal Details',
  'Profile Title & Overview',
  'Featured',
  'Licenses & Certification',
  'Skills',
  'Hourly Rates',
  'Work Experience',
  'Languages',
  'Billing Address'
]

export const SEARCH_TAGS = [
  'SAP Hana',
  'SAP ABAP',
  'Technology',
  'UI Developer',
  'Wordpress',
  'Android Developer'
]

export const ERROR_MESSAGES = {
  loginErrorMessage: 'Please login first',
  passwordNotMatchErrorMessage: 'Password not match',
  passwordErrorMessage:
    'Please enter password between 7 to 15 characters',
  emailErrorMessage: 'Please enter a valid email address',
  firstNameErrorMessage: 'Please enter first name',
  lastNameErrorMessage: 'Please enter last name',
  mobileErrorMessage: 'Please enter valid mobile number',
  displayNameErrorMessage: 'Please enter display name',
  countryErrorMessage: 'Please select country'
}

export const DURATION = {
  '15 Min': 15,
  '30 Min': 30,
  '45 Min': 45,
  '60 Min': 60
}

export const DATE_STYLE = {
  LONG: 'long',
  SHORT: 'short',
}

export const STATUS = {
  ACCEPT: 'accept',
  REJECT: 'reject',
  PENDING: 'pending',
  APPROVE: 'approve'
}

export const COLLECTION = {
  SCHEDULES: 'schedules',
  BIDS: 'bids',
  CALLS: 'calls',
}

export const Notification_Event_Type = {
  NOTIFICATION: "notification",
  EMAIL: "email",
  SMS: "sms",
}

export const Notification_Type = {
  BID: {
    BID_ACCEPTED: "bidAccepted",
    BID_REJECTED: "bidRejected",
    BID_NEGOTIATE: "bidNegotiation",
  },
  SCHEDULE: {
    SCHEDULE_RECEIVED: "scheduleReceived",
    SCHEDULE_ACCEPTED: "scheduleAccepted",
    SCHEDULE_REJECTED: "scheduleRejected",
    SCHEDULE_CHAT: "scheduleChat",
  },
  GROUP: {
    GROUP_ACCEPT: "groupAccept",
    GROUP_REJECT: "groupReject",
    GROUP_SCHEDULE: "groupSchedule",
    GROUP_CHAT: "groupChat",
    GROUP_INVITE: "groupInvite",
  },
  GET_CALL: "getCall",
  CHAT_MESSAGE: "getMessage",
}

export const Notification_Category_Type = {
  BID: "bid",
  SCHEDULE: "schedule",
  GROUP: "group",
  CHAT: "chat",
  CALL: "call",
}

export const RAZORPAY_URL = 'https://checkout.razorpay.com/v1/checkout.js'

export const WavSoundEffect =
  'https://firebasestorage.googleapis.com/v0/b/expertbunch-c5b78.appspot.com/o/kpSoundEffect.wav?alt=media&token=045bf022-0cdc-46e9-9586-013a72c100a5'
export const VttSoundEffect =
  'https://firebasestorage.googleapis.com/v0/b/expertbunch-c5b78.appspot.com/o/kpSoundEffectCaption.vtt?alt=media&token=ed1098ab-a4dc-40af-95a0-d2024577cc41'

export const FIREBASE_URL_DEEP_LINK = 'https://ebprod.page.link'