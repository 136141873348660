import { db, storage, serverTimestamp } from '@src/firebase'
import { setLodingStatusAction } from '@actions'
import { getAvg } from '@utils'
import addToaster from '@shared/Notification'
import store from '@store'
import Axios from './axios'


/** Update user profile
 * @param {Object} payload
 * @param {Object} payload.userId
 */
export const callSetUserDetailsApi = async (userId, payload) => {
  store.dispatch(setLodingStatusAction(true))
  try {
    const data = await db
      .collection('users')
      .doc(userId)
      .set({ ...payload, updatedAt: serverTimestamp }, { merge: true })
    addToaster('success', 'Your details has been updated successfully')
    store.dispatch(setLodingStatusAction(false))
    return data
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Get user profile details
 * @param {Object} payload
 * @param {Object} payload.userId
 */
export const callGetUserDetailsApi = async userId => {
  store.dispatch(setLodingStatusAction(true))
  try {
    const data = await db.collection('users').doc(userId).get()
    store.dispatch(setLodingStatusAction(false))
    if (!data.exists) {
      addToaster('warning', 'Data not found')
      return { status: 404 }
    } else {
      const _id = data.id
      // const { docs } = await db.collection(`users/${_id}/feedbacks`).get()
      // const feedbacks = docs.map(doc => doc.data())
      // const ratings = feedbacks.map(rt => rt.rating)
      // const average = getAvg(ratings)
      // const feedbacksLength = feedbacks.length
      return {
        status: 200,
        data: {
          ...data.data(),
          _id,
          // feedbacks: {
          //   reviews: feedbacks,
          //   averageRating: average,
          //   feedbacksLength
          // }
        }
      }
    }
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Get user profile details
 * @param {Object} payload
 * @param {Object} payload.userId
 */
 export const callGetUserDetailsPageApi = async userId => {
  store.dispatch(setLodingStatusAction(true))
  try {
    const data = await db.collection('users').doc(userId).get()
    if (!data.exists) {
      addToaster('warning', 'Data not found')
      store.dispatch(setLodingStatusAction(false))
      return { status: 404 }
    } else {
      const _id = data.id
      const { docs } = await db.collection(`users/${_id}/feedbacks`).get()
      const feedbacks = docs.map(doc => doc.data())
      // const ratings = feedbacks.map(rt => rt.rating)
      // const average = getAvg(ratings)
      // const feedbacksLength = feedbacks.length
      setTimeout(() => store.dispatch(setLodingStatusAction(false)), 200);
      return {
        status: 200,
        data: {
          ...data.data(),
          _id,
          feedbacks: feedbacks
          // feedbacks: {
          //   reviews: feedbacks,
          //   averageRating: average,
          //   feedbacksLength
          // }
        }
      }
    }
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Get Current user profile details realtime
 * @param {Object} payload
 * @param {Object} payload.userId
 */
export const callGetRealtimeUserDetailsApi = async (userId, resData) => {
  try {
    db.collection('users')
      .doc(userId)
      .onSnapshot(snapshot => {
        const { exists } = snapshot
        if (exists) resData({ status: 200, data: snapshot.data() })
        else resData({ status: 400 })
      })
  } catch (error) {
    addToaster('error', error.message)
  }
}

/** Update user Status
 * @param {Object} payload
 */
export const callSetUserStatusApi = (userId, payload) => {
  return db
    .collection('users')
    .doc(userId)
    .set({ ...payload, createdAt: serverTimestamp }, { merge: true })
}

/** Get top Experts profile details
 * @param {Object} payload
 */
export const callGetRealtimeTopExpertsApi = (userId, resData) => {
  return db
    .collection('users')
    .where('profileType', '==', 'vendor')
    .where('userId', '!=', userId)
    .limit(4)
    .onSnapshot(async snapshot => {
      const topExperts = await Promise.all(
        snapshot.docs.map(async item => {
          const _id = item.id
          const { docs } = await db.collection(`users/${_id}/feedbacks`).get()
          const feedbacks = docs.map(doc => doc.data())
          const ratings = feedbacks.map(rt => rt.rating)
          const average = getAvg(ratings)
          const feedbacksLength = feedbacks.length
          return {
            ...item.data(),
            feedbacks: {
              reviews: feedbacks,
              averageRating: average,
              feedbacksLength
            },
            _id
          }
        })
      )
      resData(topExperts)
    })
}

/** Get users profile details
 * @param {Object} payload
 */
export const callGetVendorsListApi = async usersId => {
  const arrayList = usersId.length ? usersId : ['']
  try {
    store.dispatch(setLodingStatusAction(true))
    const { docs } = await db
      .collection('users')
      .where('profileType', '==', 'vendor')
      .where('userId', 'in', arrayList)
      .get()
    store.dispatch(setLodingStatusAction(false))
    const data = docs.map(item => {
      const _id = item.id
      return { ...item.data(), _id }
    })
    return { status: 200, data }
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

export const callGetUsersListApi = async usersId => {
  const arrayList = usersId.length ? usersId : ['']
  try {
    store.dispatch(setLodingStatusAction(true))
    const { docs } = await db
      .collection('users')
      .where('userId', 'in', arrayList)
      .get()
    store.dispatch(setLodingStatusAction(false))
    const data = docs.map(item => {
      const _id = item.id
      return { ...item.data(), _id }
    })
    return { status: 200, data }
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Get users profile details
 * @param {Array} payload.usersid
 * @param {Function} payload.resData
 */
export const callGetRealtimeUsersListApi = (usersId = [], resData) => {
  const arrayList = usersId.length ? usersId : ['']

  db.collection('users')
    .where('profileType', '==', 'vendor')
    .where('userId', 'in', arrayList)
    .onSnapshot(snapshot => {
      const data = snapshot.docs.map(item => {
        const _id = item.id
        return { ...item.data(), _id }
      })
      resData(data)
    })
}

/** Get top Experts profile details
 * @param {Object} payload
 */
export const callFindProfileApi = async search => {
  store.dispatch(setLodingStatusAction(true))
  try {
    const { docs } = await db
      .collection('users')
      .where('profileType', '==', 'vendor')
      .where('profileOverview.profileTitle', 'in', search)
      .get()
    store.dispatch(setLodingStatusAction(false))
    return docs && docs.map(doc => doc.data())
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Upload user profile photo using user userId
 * @param {Object} payload
 * @param {Object} payload.userId
 */
export const callUploadUserPhotoApi = async (userId, payload) => {
  store.dispatch(setLodingStatusAction(true))
  try {
    const data = await storage.ref(`/images/${userId}`).put(payload)
    store.dispatch(setLodingStatusAction(false))
    return data
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Upload user profile photo using user userId
 * @param {Object} payload
 * @param {Object} payload.userId
 */
export const callUploadCompanyPhotoApi = async (userId, payload) => {
  store.dispatch(setLodingStatusAction(true))
  try {
    const data = await storage.ref(`/images/${userId}`).put(payload)
    store.dispatch(setLodingStatusAction(false))
    return data
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Upload documents using user userId
 * @param {Object} payload
 * @param {Object} payload.userId
 */
export const callUploadDocumentsApi = (userId, payload) => {
  const { name, file } = payload
  return storage.ref(`/documents/${userId}/${name}`).put(file)
}

/** Set feedback using user userId and refId
 * @param {Object} payload
 * @param {Object} payload.userId
 * @param {Object} payload.docId
 */
export const callSetFeedbackApi = async (userId, docId, collection = 'feedbacks', payload) => {
  try {
    store.dispatch(setLodingStatusAction(true))
    if(isNaN(payload.rating)) payload["rating"] = 1;
    await db
      .collection(`users/${userId}/${collection}`)
      .doc(docId)
      .set({ ...payload, createdAt: serverTimestamp }, { merge: true })
    let avgRatings = (await callGetUserDetailsApi(userId)).data.avgRatings
    if (avgRatings === undefined || avgRatings === null){
      avgRatings = payload.rating;
    } else {
      avgRatings = parseFloat(((avgRatings + payload.rating)/2).toFixed(2));
    }
    callSetUserDetailsApi(userId, {avgRatings: avgRatings})
    store.dispatch(setLodingStatusAction(false))
    return { status: 200 }
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

// OTP
export const sendOtp = payload => {
  return Axios.post('/send-otp', payload)
}

export const verifyOtp = payload => {
  return Axios.post('/verify', payload)
}
