import { db, serverTimestamp } from '@src/firebase'
import addToaster from '@shared/Notification'
import { Notification_Event_Type } from '@constants/index'

/** send notifications using user id and receiver id
 * @param {Object} payload
 * @param {Object} payload.userId
 * @param {Object} payload.receiverId
 */
export const sendNotificationApi = async (receiverId, payload) => {
  try {
    db.collection(`users/${receiverId}/notifications`)
      .doc()
      .set({ ...payload, createdAt: serverTimestamp }, { merge: true })
    return { status: 200 }
  } catch (error) {
    addToaster('error', error.message)
  }
}

export const sendMultiUsersNotificationApi = async (usersId, payload) => {
  const batch = db.batch()
  try {
    usersId.forEach(id => {
      const docRef = db.collection(`users/${id}/notifications`).doc()
      batch.set(
        docRef,
        { ...payload, createdAt: serverTimestamp },
        { merge: true }
      )
    })
    batch.commit()
    return { status: 200 }
  } catch (error) {
    addToaster('error', error.message)
  }
}

/** send notifications using user id and receiver id
 * @param {String} payload.userId
 * @param {String} payload.receiverId
 * @param {Array} payload.docs // Notifications array
 * @param {Object} payload
 */
export const callDeleteNotificationsApi = async (userId, docs) => {
  const batch = db.batch()
  try {
    docs.forEach(doc => {
      const docRef = db.collection(`users/${userId}/notifications`).doc(doc._id)
      batch.delete(docRef)
    })
    batch.commit()
    return { status: 200 }
  } catch (error) {
    addToaster('error', error.message)
  }
}

/** Get realtime notifications using user userId
 * @param {Object} payload.userId
 * @param {Object} payload.resData callback function for res Data
 */
export const callGetRealtimeNotificationsApi = async (userId, resData) => {
  try {
    db.collection(`users/${userId}/notifications`).orderBy('createdAt', 'desc').onSnapshot(snapshot => {
      if (snapshot.empty) return resData([])
      else {
        const data = snapshot.docs.map(item => {
          const _id = item.id
          return { ...item.data(), _id }
        })
        resData(data)
      }
    })
  } catch (error) {
    addToaster('error', error.message)
  }
}

/** Send notification using API
 * @param {Object} notificationPayload
 */
export const sendNotificationServerApi = notificationPayload => {
  const payload = {
    type: Notification_Event_Type.NOTIFICATION,
    payload: notificationPayload,
  }
  return Axios.post('/event-listner', payload)
}
