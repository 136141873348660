import {
  USER_AUTH_SUCCESS,
  USER_DETAILS_SUCCESS,
  SEARCH_PROFILES_SUCCESS,
  BID_DETAILS_SUCCESS,
  NOTIFICATION_SUCCESS,
  SCHEDULED_AVAILABILITY_SUCCESS,
  SCHEDULED_CALL_DETAILS_SUCCESS,
  SCHEDULED_CALL_CHATS_SUCCESS,
  WISHLIST_SUCCESS,
  GROUPLIST_SUCCESS,
  CLEAR_CURRENT_USER_DATA,
  USER_LOGOUT_SUCCESS,
  APPLICATION_LOADING_STATUS,
  USER_SIGNUP_SUCCESS
} from '@constants/actionsType'
import { getAppStore, setAppStore } from '@store'

export const initialState = {
  isAppLoading: false,
  isUserSignUp: false,
  loggedInUserData: {},
  notifications: [],
  myProfileDetails: {},
  scheduledAvailability: [],
  scheduledDetails: {},
  wishlist: [],
  grouplist: [],
  bids: {}
}

export default function reducer(state = initialState, action) {
  const userData = getAppStore()
  const { type, payload } = action
  let stateData = userData

  switch (type) {
    case USER_AUTH_SUCCESS:
      stateData = { ...state, loggedInUserData: payload }
      break
    case USER_LOGOUT_SUCCESS:
      stateData = {}
      break
    case USER_SIGNUP_SUCCESS:
      stateData = {...state, isUserSignUp: payload}
      break  
    case NOTIFICATION_SUCCESS:
      stateData = {
        ...state,
        notifications: payload
      }
      break
    case USER_DETAILS_SUCCESS:
      stateData = {
        ...state,
        myProfileDetails: payload
      }
      break
    case BID_DETAILS_SUCCESS:
      stateData = {
        ...state,
        bids: payload
      }
      break
    case WISHLIST_SUCCESS:
      stateData = {
        ...state,
        wishlist: payload
      }
      break
    case GROUPLIST_SUCCESS:
      stateData = {
        ...state,
        grouplist: payload
      }
      break
    case SEARCH_PROFILES_SUCCESS:
      stateData = { ...state, profiles: payload }
      break
    case SCHEDULED_AVAILABILITY_SUCCESS:
      stateData = { ...state, scheduledAvailability: payload }
      break
    case SCHEDULED_CALL_DETAILS_SUCCESS:
      stateData = { ...state, scheduledCallDetails: payload }
      break
    case SCHEDULED_CALL_CHATS_SUCCESS:
      stateData = { ...state, scheduledCallChats: payload }
      break
    case APPLICATION_LOADING_STATUS:
      stateData = { ...state, isAppLoading: payload }
      break
    case CLEAR_CURRENT_USER_DATA:
      stateData = {}
      break
    default:
      return stateData
  }
  setAppStore(stateData)
  return stateData
}
