import React from 'react'
import { getAppStore } from '@store'

// password between 7 to 15 characters which contain at least one numeric digit and a special character
const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/
const PASSWORD_BASIC_REGEX = /^[a-zA-Z0-9!@#$%^&*]{7,15}$/
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const PHONE_REGEX = /^[6-9]\d{9}$/

export const isUserLoggedIn = () => {
  const { loggedInUserData = {} } = getAppStore()
  return !!Object.keys(loggedInUserData).length
}

export const generateUniqueId = () => {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

/**
 *
 * @param {String} startingDate
 * @param {String} endingDate
 * @returns
 */
export const getYearsDiff = (startingDate, endingDate) => {
  let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10))
  if (!endingDate) {
    endingDate = new Date().toISOString().substr(0, 10) // need date in YYYY-MM-DD format
  }
  let endDate = new Date(endingDate)
  if (startDate > endDate) {
    let swap = startDate
    startDate = endDate
    endDate = swap
  }
  let startYear = startDate.getFullYear()
  let february =
    (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
      ? 29
      : 28
  let daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

  let yearDiff = endDate.getFullYear() - startYear
  let monthDiff = endDate.getMonth() - startDate.getMonth()
  if (monthDiff < 0) {
    yearDiff--
    monthDiff += 12
  }
  let dayDiff = endDate.getDate() - startDate.getDate()
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--
    } else {
      yearDiff--
      monthDiff = 11
    }
    dayDiff += daysInMonth[startDate.getMonth()]
  }

  return yearDiff + '.' + monthDiff
}

// Validate password
export const validatePassword = password => {
  return password && PASSWORD_BASIC_REGEX.test(password)
}

// Validate email
export const validateEmail = email => {
  return email && EMAIL_REGEX.test(email)
}

// Get alphabets trimed from start and number not allow
export const getAlphabets = value => {
  return value.replace(/[^A-Za-z ]/gi, '').trimStart()
}

// Get number alphabets not allow
export const getNumber = value => {
  return value.replace(/[^0-9 ]/gi, '').trim()
}

// Get date 19 April 2021 and time 9:00:00 AM
export const getDateTime = (d, f = 'en-GB') => {
  if (!d) return false
  const newDate = new Date(d)
  const date = newDate.toLocaleString('en-GB', {
    month: 'short',
    year: 'numeric',
    day: 'numeric',
    timeZone: 'UTC'
  })
  const time = newDate.toLocaleTimeString('en-GB')
  const dateTime = new Intl.DateTimeFormat(f, {
    dateStyle: 'medium',
    timeStyle: 'short'
  }).format(new Date(newDate))

  return { date, time, dateTime }
}

/**
 *
 * @param {*} d
 * @returns
 */
export const getDateTimestamp = (d = {}, dateStyle) => {
  if (!d) return false
  const { seconds = 0, nanoseconds = 0 } = d
  const newDate = new Date(seconds * 1000 + nanoseconds / 1000000)
  const dateTime = new Intl.DateTimeFormat('en-IN', {
    dateStyle: dateStyle,
    timeStyle: 'short'
  }).format(new Date(newDate))
  return { date: newDate, dateTime }
}

export const getStatusMarkup = status => {
  if (status === 'approve') return <span className='Accepted'>Approved</span>
  if (status === 'accept') return <span className='Accepted'>Accepted</span>
  if (status === 'reject') return <span className='Rejected'>Rejected</span>
  else return <span className='Waiting'>Waiting Approval</span>
}

export const getActiveMarkup = status => {
  if (status) return <span className='Accepted'>Active</span>
  else return <span className='Rejected'>Inactive</span>
}

// Get input value as per type alphabets not allow
export const getInputVal = (value, type, max) => {
  if (max && value.length > Number(max)) return false
  if (type && type === 'number') {
    if (value.startsWith(0)) return false
    return Number(getNumber(value))
  } else return value.trimStart()
}

// load script dynamicly
export const loadScript = src => {
  return new Promise(resolve => {
    const script = document.createElement('script')
    script.src = src
    script.onload = () => {
      resolve(true)
    }
    script.onerror = () => {
      resolve(false)
    }
    document.body.appendChild(script)
  })
}

export const getProfileData = obj => {
  const { personalDetails, profileOverview, rateDetails, ...Rest } = obj
  return { ...personalDetails, ...profileOverview, ...rateDetails, ...Rest }
}

export const getAvg = (array = []) => {
  const total = array.reduce((acc, c) => acc + c, 0)
  return total / array.length || 0
}


export const getAppURL = () => {
  const environment = process.env.APP_ENV
  if(environment === 'local') {
    return location.origin
  } 
  return process.env.APP_URL
}

export const useQuery = (queryString) => {
  return new URLSearchParams(queryString);
};

export const getParamsQuery = (paramsBody) => {
  return Object.keys(paramsBody).reduce((acc, cv, ci) => {
    acc += (Object.keys(paramsBody).length - 1) ===  ci ? `${cv}=${paramsBody[cv]}` : `${cv}=${paramsBody[cv]}&`;
  return acc;
  },'')
}