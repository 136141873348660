import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useStore } from '@store'
import { setWishlistAction } from '@actions'
import { callGetRealtimeWishlistApi } from '@api/wishlist'
import { WISHLIST_ROUTE } from '@constants/routes'
import Bookmark from '@assets/images/bookmark-white.svg'

const Wishlist = () => {
  const { dispatch, useSelector } = useStore()

  const { loggedInUserData: { userId = '' } = {}, wishlist = [] } = useSelector(
    state => {
      return {
        loggedInUserData: state.loggedInUserData,
        wishlist: state.wishlist
      }
    }
  )

  const wishlistCount = wishlist.length
  const resData = res => {
    dispatch(setWishlistAction(res))
  }

  useEffect(() => {
    if (userId) {
      const sub = callGetRealtimeWishlistApi(userId, resData)
      return () => sub
    }
  }, [userId])

  return (
    <Link to={WISHLIST_ROUTE} className='Notify'>
      <img src={Bookmark} />
      <div className='Count'>{wishlistCount}</div>
    </Link>
  )
}

export default Wishlist
