import React, { useEffect, useState, memo } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useStore, setAppStore } from '@store'
import { callSetUserStatusApi } from '@api'
import { callUserSignOutApi } from '@api/auth'
import { userAuthLogoutAction, signUpFlag } from '@actions'
import { isUserLoggedIn } from '@utils'
import history from '@history'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
// import Button from 'react-bootstrap/Button'
import {
  SIGNIN_ROUTE,
  HOME_ROUTE,
  MY_PROFILE_ROUTE,
  BECOME_FREELANCER_ROUTE,
  DASHBOARD_ROUTE,
  MYTASK_ROUTE,
  JOBS_ROUTE,
  HOW_IT_WORKS_ROUTE,
  WISHLIST_ROUTE,
  SEARCH_ROUTE,
  UPCOMING_CALLS
} from '@constants/routes'
import Notifications from './Notifications'
import addToaster from '@shared/Notification'
import DefaultPic from '@assets/images/DefaultPic.jpg'
import Logo from '@assets/images/logo.svg'
import './index.scss'
import Wishlist from './Wishlist'

const Header = props => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  const { onBackDashboard, onBack, title, childTitle, historyBack = false } = props

  const { useSelector, dispatch } = useStore()

  const {
    loggedInUserData = {},
    myProfileDetails: { profileType, personalDetails: { profilePic } = {} } = {}
  } = useSelector(state => {
    return {
      loggedInUserData: state.loggedInUserData,
      myProfileDetails: state.myProfileDetails
    }
  })

  const { userId, userType = 'individual' } = loggedInUserData

  const handleLogout = async () => {
    try {
      const { status } = await callUserSignOutApi()
      if (status === 200) {
        await callSetUserStatusApi(userId, { isOnline: false })
        setAppStore({})
        dispatch(userAuthLogoutAction())
        addToaster('success', 'Logged Out Successfully')
        history.push(SIGNIN_ROUTE)
      }
    } catch (error) {
      addToaster('error', error.message)
    }
  }

  // async function handleTabFalse() {
  //   await callSetUserStatusApi(userId, { isOnline: false })
  // }
  // async function handleTabTrue() {
  //   await callSetUserStatusApi(userId, { isOnline: true })
  // }

  const handleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }
  const handleSignUpFlag = () => {
    dispatch(signUpFlag(false));
  }

  // useEffect(() => {
  //   if (userId) {
  //     handleTabTrue()
  //     window.addEventListener('blur', handleTabFalse)
  //     window.addEventListener('beforeunload', handleTabFalse)
  //     window.addEventListener('focus', handleTabTrue)

  //     return () => {
  //       window.removeEventListener('blur', handleTabFalse)
  //       window.removeEventListener('beforeunload', handleTabFalse)
  //       window.removeEventListener('focus', handleTabTrue)
  //     }
  //   }
  // }, [userId])

  const backUrl = onBackDashboard
    ? `${DASHBOARD_ROUTE}${onBackDashboard}`
    : onBack

  return (
    <>
      <div className='Headerbar'>
        <Container>
          <div className='NavBar'>
            <Link to={HOME_ROUTE} className='Logo'>
              <img
                alt='Profile Photo'
                src={Logo}
                className='d-inline-block align-top'
              />
            </Link>
            <div className='mr-auto LeftMenu'>
              <NavLink to={HOME_ROUTE} className='NavItem'>
                Home
              </NavLink>
              <NavLink to='services' className='NavItem'>
                Our Services
              </NavLink>
              {isUserLoggedIn() &&
                profileType !== 'vendor' &&
                userType !== 'organization' && (
                  <NavLink
                    to={`${MY_PROFILE_ROUTE}${BECOME_FREELANCER_ROUTE}`}
                    className='NavItem'
                  >
                    Become a freelancer
                  </NavLink>
                )}
              <NavLink to={JOBS_ROUTE} className='NavItem'>
                Jobs
              </NavLink>
            </div>

            <div className='align-items-center RightNav'>
              <div className='desktopMenu'>
                {isUserLoggedIn() && (
                  <>
                    <Link
                      to={`${SEARCH_ROUTE}`}
                      className='headericon'
                    >
                      <i className="bi bi-search"></i>
                    </Link>
                    <Wishlist />
                    <Notifications />
                    <Link
                      to={`${DASHBOARD_ROUTE}${UPCOMING_CALLS}`}
                      className='headericon'
                    >
                      <i className="bi bi-calendar-check"></i>
                    </Link>
                    <Link
                      to={`${DASHBOARD_ROUTE}${MYTASK_ROUTE}`}
                      className='headericon'
                    >
                      <i className="bi bi-columns-gap" />
                    </Link>
                  </>
                )}
                <div className='LoginUserDetails'>
                  {isUserLoggedIn() ? (
                    <NavDropdown
                      title={
                        <img
                          src={profilePic || DefaultPic}
                          className='UserProfilePic'
                        />
                      }
                      className='ProfileMenu'
                    >
                      <Link
                        to={`${DASHBOARD_ROUTE}${MYTASK_ROUTE}`}
                        className='DeopdownLink'
                      >
                        Dashboard
                      </Link>
                      <Link to={MY_PROFILE_ROUTE} className='DeopdownLink' onClick={handleSignUpFlag}>
                        My Profile
                      </Link>

                      <NavDropdown.Divider />
                      <span onClick={handleLogout} className='DeopdownLink'>
                        Logout
                      </span>
                    </NavDropdown>
                  ) : (
                    <>
                      <div className='mr-auto LeftMenu'>
                        <NavLink to={HOW_IT_WORKS_ROUTE} className='NavItem'>
                          How it Works
                        </NavLink>
                        <NavLink to='support' className='NavItem'>
                          Support
                        </NavLink>
                        <NavLink to={SIGNIN_ROUTE} className='NavItem'>
                          Login
                        </NavLink>
                      </div>
                      {/* <Button href={SIGNIN_ROUTE}>Sign In</Button> */}
                    </>
                  )}
                </div>
              </div>

              {/* Mobile menu */}
              <div className='mobileMenu'>
                {isUserLoggedIn() && <Notifications />}
                {isMobileMenuOpen ? (
                  <button className='burgerMenu' onClick={handleMobileMenu}>
                    <i className='bi bi-x'></i>
                  </button>
                ) : (
                  <button className='burgerMenu' onClick={handleMobileMenu}>
                    <i className='bi bi-list'></i>
                  </button>
                )}
                {isMobileMenuOpen && (
                  <div className='mobileMenuList'>
                    <ul>
                      <li>
                        <NavLink to={HOME_ROUTE} className='NavItem'>
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to='services' className='NavItem'>
                          Our Services
                        </NavLink>
                      </li>
                      {isUserLoggedIn() &&
                        profileType !== 'vendor' &&
                        userType !== 'organization' && (
                          <li>
                            <NavLink
                              to={`${MY_PROFILE_ROUTE}${BECOME_FREELANCER_ROUTE}`}
                              className='NavItem'
                            >
                              Become a freelancer
                            </NavLink>
                          </li>
                        )}
                      <li>
                        <NavLink to={JOBS_ROUTE} className='NavItem'>
                          Jobs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={HOW_IT_WORKS_ROUTE} className='NavItem'>
                          How it Works
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to='support' className='NavItem'>
                          Support
                        </NavLink>
                      </li>
                    </ul>
                    {isUserLoggedIn() && (
                      <ul>
                        <li>
                          <NavLink to={WISHLIST_ROUTE} className='NavItem'>
                            Wishlist
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            to={`${DASHBOARD_ROUTE}${MYTASK_ROUTE}`}
                            className='NavItem'
                          >
                            Dashboard
                          </NavLink>
                        </li>
                        <li>
                          <Link to={MY_PROFILE_ROUTE} className='NavItem' onClick={handleSignUpFlag}>
                            My Profile
                          </Link>
                        </li>
                        <li>
                          <span onClick={handleLogout} className='NavItem'>
                            Logout
                          </span>
                        </li>
                      </ul>
                    )}
                    {!isUserLoggedIn() && (
                      <ul>
                        <li>
                          <NavLink to={SIGNIN_ROUTE} className='NavItem'>
                            Login
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
      {title && (
        <Container>
          <div className='SubHeader'>
            <span className='TitleGroup'>
              {backUrl && (
                <>
                  <Link to={backUrl}>
                    <i className='bi bi-arrow-left backicon'></i>
                  </Link>{' '}
                </>
              )}
              {!backUrl && historyBack && (
                <>
                  <span style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
                    <i className='bi bi-arrow-left backicon'></i>
                  </span>{' '}
                </>
              )}
              {title}{' '}
              <span className='ChildTitle'>
                {childTitle && `/ ${childTitle}`}
              </span>
            </span>
          </div>
        </Container>
      )}
    </>
  )
}

export default memo(Header)
