export const USER_AUTH_SUCCESS = 'USER_AUTH_SUCCESS'
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const SEARCH_PROFILES_SUCCESS = 'SEARCH_PROFILES_SUCCESS'
export const BID_DETAILS_SUCCESS = 'BID_DETAILS_SUCCESS'
export const EXPERT_PROFILES_SUCCESS = 'EXPERT_PROFILES_SUCCESS'
export const NOTIFICATION_SUCCESS = 'NOTIFICATION_SUCCESS'
export const SCHEDULED_AVAILABILITY_SUCCESS = 'SCHEDULED_AVAILABILITY_SUCCESS'
export const SCHEDULED_CALL_DETAILS_SUCCESS = 'SCHEDULED_CALL_DETAILS_SUCCESS'
export const SCHEDULED_CALL_CHATS_SUCCESS = 'SCHEDULED_CALL_CHATS_SUCCESS'
export const WISHLIST_SUCCESS = 'WISHLIST_SUCCESS'
export const GROUPLIST_SUCCESS = 'GROUPLIST_SUCCESS'
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS'
export const CLEAR_CURRENT_USER_DATA = 'CLEAR_CURRENT_USER_DATA'
export const APPLICATION_LOADING_STATUS = 'APPLICATION_LOADING_STATUS'
