import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { isUserLoggedIn } from '@utils'
import { SIGNIN_ROUTE } from '@constants/routes'
import { useRouteMatch } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const PrivateRoute = ({ component: Comp, ...props }) => {
  const location = useLocation()
  const routeByPassAuth = ['/dashboard/my-company-invite/']
  const showComponent = routeByPassAuth.some(route => location.pathname.startsWith(route))
  const redirectLink = routeByPassAuth.some(route => location.pathname.startsWith('/dashboard/my-company')) ? `?redirect=${location.pathname}`: '';
  
  return (
    // <div></div>
    <Route
      {...props}
      render={() => {
        return isUserLoggedIn() || showComponent ? (
          <Comp />
        ) : (
          <Switch>
            <Redirect
              to={{
                pathname: `${SIGNIN_ROUTE}${redirectLink}`
              }}
            />
          </Switch>
        )
      }}
    />
  )
}

export default PrivateRoute
