import { createContext } from 'react'
import rootReducer from '@reducers/'
import { createStore } from 'redux'
import { useDispatch, useSelector } from 'react-redux'

/**
 Set store globally
 */
export const setAppStore = store => {
  localStorage.setItem('hanaStore', JSON.stringify(store))
}

/**
 This store can use globally out of componet
 */
export const getAppStore = () => {
  return JSON.parse(localStorage.getItem('hanaStore')) || {}
}

/**
 * This is the actual context. Needs to be injected into `useContext` to
 * get store functionalities
 */
export const Store = createContext()

/**
 * Simple hook to get store.
 */
export const useStore = () => {
  const dispatch = useDispatch()
  return {
    dispatch,
    useSelector
  }
}

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
