import React, { useState } from 'react'
import { Row, Col, Container } from 'react-bootstrap'

import { Modal } from 'react-bootstrap';
import './index.scss'
import LogoBlack from '@assets/images/logo-black.svg'
import {
  TERMS_AND_CONDITIONS_ROUTE,
  PRIVACY_POLICY_ROUTE,
  AFFILIATE_ROUTE,
  HOW_IT_WORKS_ROUTE,
  // SERVICES,
  // FAQROUTE
} from '@constants/routes'
import { Link } from 'react-router-dom'

const Footer = () => {

  const [showModal, setShowModal] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    setShowModal(true);
    console.log(showModal);
  };
  return (
    <>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Registered Address</Modal.Title>
        </Modal.Header>
        <br />
        <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', marginBottom: '10px' }}>
          ExpertBunch@IBIZ TECHNOVA PVT. LTD.
        </p>
        <p style={{ textAlign: 'center', fontSize: '14px', lineHeight: '1.5' }}>
          Aster-1104, Regency Garden, Plot 10,<br />
          Navi Mumbai-410210, MH, INDIA.
        </p>
      </Modal>
      <section className='Footer'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg={3}>
              <div className='footer-branding'>
                <img src={LogoBlack} />
              </div>
              <ul className='LinkGroup mt-5'>
                <li>
                  <i className='bi bi-arrow-right-circle-fill'></i> Aggregating
                  Experts
                </li>
                <li>
                  <i className='bi bi-arrow-right-circle-fill'></i> Converging
                  Skills
                </li>
                <li>
                  <i className='bi bi-arrow-right-circle-fill'></i> SOS Solution
                  Platform with Bid
                </li>
                <li>
                  <i className='bi bi-arrow-right-circle-fill'></i> Your Price
                  (Bid), Your convenience (Schedule)
                </li>
                <li>
                  <i className='bi bi-arrow-right-circle-fill'></i> A platform
                  for Freelancers to work from anywhere
                </li>
              </ul>
            </Col>
            <Col lg={9}>
              <Row>
                <Col lg={4} className='mb-5'>
                  <ul className='LinkGroup mt-4'>
                    <li className='LingTitle'>FOR CLIENTS</li>
                    {/* <li>
                      - <Link to={`${FAQROUTE}`}>FAQ</Link>
                    </li> */}
                    <li>
                      - <a href='#'>Hire Experts</a>
                    </li>
                    <li>
                      - <a href='#'>Know More</a>
                    </li>
                  </ul>
                </Col>
                <Col lg={4} className='mb-5'>
                  <ul className='LinkGroup mt-4'>
                    <li className='LingTitle'>
                      RESOURCES PROVIDER
                    </li>
                    <li>
                      - <a href='#'>Become Service provider company</a>
                    </li>
                    <li>
                      - <a href='#'>Add Resources</a>
                    </li>
                    <li>
                      - <a href='#'>Promote your Service</a>
                    </li>
                    <li>
                      - <a href='#'>PartnerHub</a>
                    </li>
                  </ul>
                </Col>
                <Col lg={4} className='mb-5'>
                  <ul className='LinkGroup mt-4'>
                    <li className='LingTitle'>LINKS</li>
                    <li>
                      - <a href='#'>About Us</a>
                    </li>
                    <li>
                      {/* - <Link to={`${SERVICES}`}>Services</Link> */}
                    </li>
                    <li>
                      - <a href='#'>Blogs</a>
                    </li>
                    {/* <li>
                      - <Link to={`${HOW_IT_WORKS_ROUTE}`}>How it works</Link>
                    </li> */}
                    <li>
                      {/* - <Link to={`${SERVICES}`}>Services</Link> */}
                    </li>

                  </ul>
                </Col>
                <Col lg={4} className='mb-5'>
                  <ul className='LinkGroup'>
                    <li className='LingTitle'>FOR EXPERTS</li>
                    <li>
                      - <a href='#'>Become Freelancer</a>
                    </li>
                    <li>
                      - <a href='#'>FAQ</a>
                    </li>
                    {/* <li>
                      - <Link to={`${SERVICES}`}>Services</Link>
                    </li> */}
                    <li>
                      - <a href='#'>Support</a>
                    </li>
                  </ul>
                </Col>
                <Col lg={4} className='mb-5'>
                  <ul className='LinkGroup'>
                    <li className='LingTitle'>FOR AFFILIATES</li>

                    <li>
                      - <Link to={`${AFFILIATE_ROUTE}`}>Refer & Earn</Link>
                    </li>
                    <li>
                      - <a href='#'>How it benefits</a>
                    </li>
                    <li>
                      - <a href='#'>How to Monitor</a>
                    </li>
                    <li>
                      -{' '}
                      <Link to={`${TERMS_AND_CONDITIONS_ROUTE}`}>
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </Col>
                <Col lg={4} className='mb-5'>
                  <ul className='LinkGroup'>
                    <li className='LingTitle'>LEGAL</li>

                    <li>
                      -{' '}
                      <Link to={`${TERMS_AND_CONDITIONS_ROUTE}`}>
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      -{' '}
                      <Link to={`${PRIVACY_POLICY_ROUTE}`}>Privacy Policy</Link>
                    </li>
                    <li>
                      - <a href='#'>Cookie Policy</a>
                    </li>
                    <li>
                      - <a href='#' onClick={(event) => handleClick(event)}>Registered Address</a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='footer-contact'>
            <Col md={3}>
              <h2>QUICK CONTACT</h2>
            </Col>
            <Col md={2}>
              <h4>CALL</h4>
              <a href='tel:'> </a>
            </Col>
            <Col md={3}>
              <h4>EMAIL</h4>
              <a href='mailto:contact@expertbunch.com'>
                contact@expertbunch.com
              </a>
            </Col>
            <Col md={2}>
              <h4>GET SOCIAL</h4>
              <div className='footer-social'>
                <a href='#' className='mr-3'>
                  <i className='bi bi-facebook'></i>
                </a>
                <a href='#' className='mr-3'>
                  <i className='bi bi-twitter'></i>
                </a>
                <a href='#' className='mr-3'>
                  <i className='bi bi-youtube'></i>
                </a>
              </div>
            </Col>
            <Col md={2}>
              {/* <h4>BLOGS</h4> */}
              <a href='' className='footer-blog'>
                Explore Blogs
              </a>
            </Col>
          </Row>
        </Container>
        <div className='CopyRight'>
          <Container className='text-center'>
            {' '}
            Ibiz Technova Pvt Ltd © 2023 &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;
            All Rights Reserved. &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;{' '}
            <Link
              to={`${PRIVACY_POLICY_ROUTE}`}
              className='text-decoration-none text-white'
            >
              Privacy Policy
            </Link>
          </Container>
        </div>
      </section>

    </>
  )
}

export default Footer
