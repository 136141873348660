export const HOME_ROUTE = '/'
export const SEARCH_ROUTE = '/search'
export const SIGNIN_ROUTE = '/signin'
export const SIGNUP_ROUTE = '/signup'
export const RESET_PASSWORD_ROUTE = '/reset-password'
export const BID_DETAILS_ROUTE = '/bid-details'
export const DASHBOARD_ROUTE = '/dashboard'
export const PROFILE_VIEW_ROUTE = '/profile-view'
export const BECOME_FREELANCER_ROUTE = '/become-freelancer'
export const SCHEDULE_CALL = '/schedule-call'
export const SCHEDULE_GROUP = '/schedule-group'
export const SCHEDULED_CALL = '/scheduled-call'
export const RECEIVED_SCHEDULEDS = '/received-schedule'
export const REQUEST_SCHEDULEDS = '/request-schedule'
export const RECEIVED_BIDS = '/received-bids'
export const SUBMITED_BIDS = '/submited-bids'
export const RECEIVED_LONG_BIDS = '/received-long-bids'
export const SUBMITED_LONG_BIDS = '/submited-long-bids'
export const RECEIVED_CALLS = '/calls-received'
export const CALLS_MADE = '/calls-made'

export const RESOURCE_RECEIVED_BIDS = '/resource-received-bids'
export const RESOURCE_RECEIVED_LONG_BIDS = '/resource-received-long-bids'
export const RESOURCE_RECEIVED_SCHEDULEDS = '/resource-received-schedule'

export const PAGE_NOT_FOUND_ROUTE = '/notfound'
export const VIDEO_CALL_ROUTE = '/video-call'
export const WISHLIST_ROUTE = '/wishlist'
export const MESSENGER_ROUTE = '/messenger'
export const MY_PROFILE_ROUTE = '/my-profile'
export const MYTASK_ROUTE = '/mytastk'
export const UPCOMING_CALLS = '/upcoming-calls'
export const MYTASK_RESOURCE_ROUTE = '/my-task-resource'
export const MYWALLET_ROUTE = '/mywallet'
export const ROUPS_ROUTE = '/groups'
export const ROUP_ROUTE = '/group'
export const ADD_GROUP_USER_ROUTE = '/add-group-user'
export const POSTS_ROUTE = '/posts'
export const JOBS_ROUTE = '/jobs'
export const COMPANY_ROUTE = '/my-company'
export const HOW_IT_WORKS_ROUTE = '/how-it-works'
export const COMPANY_INVITE_LINK = '/my-company-invite'
export const AFFILIATE_LINK = '/affiliate-link/invite'
export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-conditions'
export const PRIVACY_POLICY_ROUTE = '/privacy-policy'
export const AFFILIATE_ROUTE = '/affiliate'
export const MY_AFFILIATE_ROUTE = '/my-affiliate'
export const AFFILIATE_REVENUE_ROUTE = '/affiliate-revenue'
export const COMPANY_INFO_ROUTE = '/company'

//Zego
export const ZEGO_VIDEO_CALL_ROUTE = '/new-video-call'
