import React from 'react'
import ReactDom from 'react-dom'
import { Provider } from 'react-redux'
import store from '@store'
import App from './App'

const MainApp = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  )
}

ReactDom.render(<MainApp />, document.getElementById('root'))
