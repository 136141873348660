import { db, serverTimestamp } from '@src/firebase'
import { setLodingStatusAction } from '@actions'
import addToaster from '@shared/Notification'
import store from '@store'
import { FIREBASE_URL_DEEP_LINK } from '@constants/'
import { AFFILIATE_LINK, COMPANY_INVITE_LINK } from '@constants/routes'
import { getAppURL } from '@utils/'


/** Get Affiliate Profiles
 * @param {string} userId
 */
 export const getAffiliateProfiles = async (
  userId,
) => {
  store.dispatch(setLodingStatusAction(true))
  try {
    
    const affiliateUsers = await db.collection('users').where('referredBy', '==', userId).get()
    const profiles = affiliateUsers.docs.map(u => u.data())

    store.dispatch(setLodingStatusAction(false))

    return { status: 200, data: profiles }
  } catch (error) {
    console.error(error)
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Get Affiliate transaction
 * @param {string} userId
 */
 export const getAffiliateTransactions = async (
  userId,
) => {
  store.dispatch(setLodingStatusAction(true))
  try {
    const transactionDocs = await db.collection(`users/${userId}/transactions`).where('type', '==', 'service').orderBy('createdAt', 'desc').get()
    if(transactionDocs.size === 0) {
      return { status: 200, data: {} }
    } 
    const transactions = {}
    transactionDocs.docs.forEach(trans => {
      const t = trans.data()
      if(!transactions[t.paidRcvdUserId]) {
        transactions[t.paidRcvdUserId] = {
          displayName: '',
          email: '',
          amount: t.amount,
          lastAmount: t.amount
        }
      }
      else {
        transactions[t.paidRcvdUserId].amount += t.amount
      }
    })

    const userIds = Object.keys(transactions)


    if(userIds.length === 0) {
      return { status: 200, data: {} }
    } 
    const affiliateUsers = await db.collection('users').where('userId', 'in', userIds).get()
    affiliateUsers.docs.forEach(u => {
      const user = u.data()
      transactions[u.id].displayName = user.personalDetails.displayName
      transactions[u.id].email = user.email
    })

    store.dispatch(setLodingStatusAction(false))

    return { status: 200, data: transactions }
  } catch (error) {
    console.error(error)
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

// POST https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=api_key
// Content-Type: application/json

// {
//   "dynamicLinkInfo": {
//     "domainUriPrefix": "https://example.page.link",
//     "link": "https://www.example.com/",
//     "androidInfo": {
//       "androidPackageName": "com.example.android"
//     },
//     "iosInfo": {
//       "iosBundleId": "com.example.ios"
//     }
//   }
// }

/** Invite Users to Join Company
 * @param {string} email
 * @param {string} userId
 */
export const inviteUserWithLink = async (
  email,
  userId
) => {
  store.dispatch(setLodingStatusAction(true))
  try {
    store.dispatch(setLodingStatusAction(false))
    // const env = process.env

    const userDoc = db.collection('users').doc(`${userId}`)
    const user = await userDoc.get()
    let { referralLink } = user.data()


    if(!referralLink) {
      referralLink = await generateReferralLink(userId)

      setReferralLink()
    }

    
    const inviteLink =  referralLink
    
    console.log({inviteLink})
    
    if(inviteLink) {
      addToaster('success', `Invite link sent via email to ${email}`)
    }
    
    return { status: 200 }
  } catch (error) {
    store.dispatch(setLodingStatusAction(false))
    addToaster('error', error.message)
  }
}

/** Create Referral Link for User
 * @param {string} userId
 * 
 * @return {Promise<string>}
 */
export const generateReferralLink = async (userId) => {
  const url = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.API_KEY}`

  const appURL = getAppURL()

  const inviteId = userId
  const urlPrefix = FIREBASE_URL_DEEP_LINK
  const link = AFFILIATE_LINK
  const response = await fetch(url, {
    method: 'post',
    body: JSON.stringify({
        dynamicLinkInfo: {
          domainUriPrefix: urlPrefix,
          link: `${appURL}${link}/${inviteId}`,
        }
      })
  })

  const { shortLink } = await response.json()

  return shortLink
}

/**
 * @param {string} userId
 * @param {string} referralLink
 */
export const setReferralLink = (userId, referralLink) => {
  const userDoc = db.collection('users').doc(`${userId}`)

  userDoc.update({
    referralLink: referralLink,
  })
}

/**
 * @param {string} userId
 * @param {string} referralLink
 */
export const getReferralLink = async (userId) => {
  const userDoc = db.collection('users').doc(`${userId}`)
  const user = await userDoc.get()
  const { referralLink } = user.data()

  return referralLink
}


/**
 * @param {string} userId
 * @param {string} affiliateId
 */
 export const setInvitedBy = (userId, affiliateId) => {
  const userDoc = db.collection('users').doc(`${userId}`)

  userDoc.update({
    referredBy: affiliateId,
  })
}